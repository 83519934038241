<script setup>
import GlobalAlert from "@/views/common/GlobalAlert.vue";
import { onMounted, onUnmounted, getCurrentInstance, computed } from "vue";
import { subscribeToAlertTopic, unsubscribeFromAlertTopic } from "@/mqtt/mqttService";
import { useStore } from "vuex";

const store = useStore();
const { proxy } = getCurrentInstance();

const enterpriseName = computed(() => {
  return store.state.user ? store.state.user.user_enterprise : null;
});

/*onMounted(() => {
  if (enterpriseName.value) {
    console.log("##mqtt 구독 시작:", enterpriseName.value);
    const topic = `/${enterpriseName.value}/alertData`;
    subscribeToAlertTopic(proxy, topic);
  } else {
    console.error("Enterprise name is not set.");
  }
});*/

onUnmounted(() => {
  unsubscribeFromAlertTopic();
});
</script>

<template>
  <div id="app">
    <GlobalAlert />
    <router-view />
  </div>
</template>
