<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axiosInstance from "@/service/axiosInstance"; // Vuex에서 useStore 가져오기

const { layoutConfig, layoutState, onMenuToggle } = useLayout();
const store = useStore(); // Vuex store 사용하기

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();


onMounted(() => {
    bindOutsideClickListener();
  // userName이 없을 경우 메뉴를 닫힌 상태로 설정
  if (!userName.value) {
    // 메뉴 모드에 따라 메뉴 상태를 초기화합니다.
    if (layoutConfig.menuMode.value === 'overlay') {
      layoutState.overlayMenuActive.value = false;
    } else {
      layoutState.staticMenuDesktopInactive.value = true;
      layoutState.staticMenuMobileActive.value = false;
    }
  }
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

/*const logoUrl = computed(() => {
    return `/demo/images/logo/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.png`;
});*/

const logoUrl = computed(() => {
    return `/demo/images/logo/SYMON3.png`;
});

const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
    topbarMenuActive.value = false;
    router.push('/documentation');
};
const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const home = async () => {
  router.push({ name: 'login' });
}

const logout = async () => {
  try {

    const response = await axiosInstance.post('/logout', null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
    // 서버 응답이 없는 경우 또는 'data' 속성이 없는 경우에 대한 예외 처리
    if (!response || !response.data) {
      console.error('서버 응답이 유효하지 않습니다.');
    }else {
      await store.dispatch('logout');
      alert("로그아웃을 완료했습니다.");
      sessionStorage.removeItem('originalSerialKey');
      sessionStorage.removeItem('preLoginRoute');
      router.push({name: 'login'}); // 라우터를 사용하여 페이지 이동
    }

  } catch (error) {
    // 오류 발생 시 콘솔에 오류 메시지 출력
    // showMessage('로그인에 실패했습니다. 다시 시도해주세요.');
  }
};

// 사용자 이름을 가져오기 위해 computed 속성 추가
const userName = computed(() => {
  return store.state.user ? store.state.user.user_name : null;
});

const enterpriseName = computed(() => {
  return store.state.user ? store.state.user.user_enterprise : null;
});


</script>

<template>
    <div class="layout-topbar">

      <button class="p-link layout-menu-button layout-topbar-button" :class="{ 'invisible': !userName }" @click="onMenuToggle()">
        <i class="pi pi-bars"></i>
      </button>

      <router-link to="/dashboard" class="layout-topbar-logo">
        <img :src="logoUrl" alt="logo" />
<!--        <span>SYMON(Beta)</span>-->
      </router-link>

      <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
          <i class="pi pi-ellipsis-v"></i>
      </button>

      <div class="layout-topbar-menu" :class="topbarMenuClasses">
        <div class="text-900 font-medium text-xl">{{ userName || '게스트' }}님 환영합니다.</div>
<!--
            <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
                <i class="pi pi-calendar"></i>
                <span>Calendar</span>
            </button>
            <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
                <i class="pi pi-user"></i>
                <span>Profile</span>
            </button>
          -->
        <!-- userName이 있을 때만 로그아웃 버튼을 표시합니다. -->
        <button v-if="userName" @click="logout" class="p-link layout-topbar-button">
          <i class="pi pi-sign-out"></i>
          <span>Settings</span>
        </button>

        <button v-else @click="home" class="p-link layout-topbar-button">
          <i class="pi pi-home"></i>
          <span>Settings</span>
        </button>

      </div>
  </div>
</template>

<style lang="scss" scoped>

.layout-topbar-menu {
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
}

.invisible {
  visibility: hidden;
}

</style>
