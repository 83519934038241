<template>
  <div class="global-alert">
    <div
        v-for="(alert, index) in alerts"
        :key="index"
        class="alert-card"
        :class="`alert-${alert.alert_warning_level.toLowerCase()}`"
        :style="{ top: `${10 + index * 65}px` }"
    >
      <span class="alert-text">
        {{ alert.camera_ip ? `${alert.camera_ip} ` : '' }}
        {{ alert.alert_warning_level }} ({{ alert.alert_warning_value }}) - {{ alert.alert_warning_type }}
        <br />
        {{ formatDate(alert.event_date) }}
      </span>
      <div class="alert-buttons">
        <button class="alert-dismiss-button" @click="removeAlert(index)">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// Vuex의 alerts 상태 가져오기
const alerts = computed(() => store.state.alert.alerts);

// 날짜 포맷팅 함수
const formatDate = (date) => {
  if (!date) return "날짜 정보 없음";
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };
  return new Date(date).toLocaleString("ko-KR", options);
};

// 알림 삭제 함수
const removeAlert = (index) => {
  store.dispatch("alert/removeAlert", index);
};
</script>

<style scoped>
.global-alert {
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 9999;
  width: 500px;
}

/* 주어진 스타일 적용 */
.alert-card {
  position: fixed;
  right: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  min-height: 40px;
  transition: background-color 0.3s ease-in-out;
}

.alert-text {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alert-buttons {
  display: flex;
  gap: 8px;
}

.alert-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.alert-buttons button:hover {
  background-color: #0056b3;
}

/* 경고 수준별 배경 색상 */
.alert-alert1 {
  background-color: #efc4cc; /* 연한 노란색 */
}

.alert-alert2 {
  background-color: #f8839d; /* 중간 노란색 */
}

.alert-alert3 {
  background-color: #ef103b; /* 진한 주황색 */
}
</style>
