<script setup>
import {computed, ref, onMounted} from 'vue';
import { useStore } from 'vuex';

const store = useStore();

import AppMenuItem from './AppMenuItem.vue';

const enterprise_seq = computed(() => store.state.user.enterprise_seq);

const isMobile = computed(() => {
  return window.matchMedia("(max-width: 768px)").matches;
});

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' }],
        requiresRole: ['syopt', 'partner', 'admin', 'user'] // 'admin'과 'user' 권한이 필요한 메뉴 항목
    },
  {
    label: 'ENTERPRISE',
    items: [{ label: '기업 추가', icon: 'pi pi-plus-circle', to: '/syopt/enterprisejoin' },
            { label: '기업 정보 관리', icon: 'pi pi-sitemap', to: '/syopt/enterpriselist' },
            { label: '기업 SMS 관리', icon: 'pi pi-comments', to: '/syopt/enterprisesmslist' }],
    requiresRole: ['syopt', 'partner']

  },
  {
    label: 'CAMERA',
    items: [/*{ label: isMobile.value ? '모니터링(모바일버전)' : '모니터링(PC버전)', icon: 'pi pi-desktop', to: isMobile.value ? '/camera/MobileCameraView' : '/camera/PcCameraView' },*/
            { label: '카메라 관리', icon: 'pi pi-camera', to: '/camera/cameralist' },
            /*{ label: 'KinesisTestPage', icon: 'pi pi-camera', to: '/camera/KinesisTest' },*/
            { label: 'MQTT 디바이스 인증서 관리', icon: 'pi pi-shield', to: '/syopt/certificationCameraList.vue', requiresRole: ['syopt'] }],
    requiresRole: ['syopt', 'partner', 'admin', 'user']
  },
  {
    label: 'USER',
    items: [{ label: '회원 추가', icon: 'pi pi-user-plus', to: '/user/userJoin' },
            { label: '회원 관리', icon: 'pi pi-users', to: '/user/userList' }],
    requiresRole: ['syopt', 'partner', 'admin']
  },
/*  {
    label: 'DATA-DASHBOARD',
    items: [{ label: '차트데이터(개발중)', icon: 'pi pi-desktop', to: '/dataDashboard/chart' },
            { label: '데이터(개발중)', icon: 'pi pi-desktop', to: '/dataDashboard/menu' },],
    requiresRole: ['syopt']
  },*/
  {
    label: 'EVENT',
    items: [{ label: isMobile.value ? '경고 이벤트(MOBILE)' : '경고 이벤트 리스트(PC)', icon: 'pi pi-exclamation-triangle', to: isMobile.value ? '/event/MobileAlertEventList' : '/event/PcAlertEventList' },],
    requiresRole: ['syopt', 'partner', 'admin', 'user']
  },
  {
    label: 'SERIALKEY',
    items: [{ label: '시리얼 키 관리', icon: 'pi pi-key', to: '/serialKey/SerialKeyList' }],
    requiresRole: ['syopt', 'partner', 'admin'] // 'admin'과 'user' 권한이 필요한 메뉴 항목
  },
  {
    label: 'SMS',
    items: [{ label: '사용자 관리', icon: 'pi pi-comment', to: computed(() => {
        return '/sms/smsUserList/' + (enterprise_seq.value || null);
      })}],
    requiresRole: ['syopt', 'partner', 'admin', 'user']
  },
  {
    label: 'WORKS',
    items: [...(!isMobile.value ? [{ label: 'SYMON-FDS 다운로드', icon: 'pi pi-save', to: '/works/SYMON-FDSDownload' }] : []),
            ...(!isMobile.value ? [{ label: 'SYMON-FDS 매뉴얼', icon: 'pi pi-pencil', to: '/works/manual/ManualUserView/SYMON-FDS' }] : []),
            { label: '프로그램 버전 관리', icon: 'pi pi-wrench', to: '/works/ProgramList', requiresRole: ['syopt'] },
            { label: '매뉴얼 관리', icon: 'pi pi-file-edit', to: '/works/manual/ManualList', requiresRole: ['syopt'] },
            { label: 'mqtt 개발 테스트', icon: 'pi pi-wrench', to: '/works/mqttTest', requiresRole: ['syopt'] },
            { label: '서비스 요청(개발중)', icon: 'pi pi-wrench', to: '/works/customerService/CustomerServiceRequestList', requiresRole: ['syopt'] },
            { label: '공지 사항(개발중)', icon: 'pi pi-book', to: '/works/NoticeList', requiresRole: ['syopt'] },
            { label: '자주 묻는 질문(개발중)', icon: 'pi pi-question', to: '/works/FaqList', requiresRole: ['syopt'] },
            { label: '자료실', icon: 'pi pi-inbox',
              items: [
                { label: '삼양옵틱스 자료실', icon: 'pi pi-inbox', to: '/works/ResourceList/typesam'},
                { label: '파트너 자료실(사용x)', icon: 'pi pi-inbox', to: '/works/ResourceList/typepa'}
              ] , requiresRole: ['syopt']
            }],
    requiresRole: ['syopt', 'partner', 'admin', 'user']
  },

  /*to: '/works/ResourceList'*/

]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
