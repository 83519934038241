// src/store/vuex.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import alert from "./modules/alert";

export default createStore({
    state: {
        // 상태(state) 정의
        user: null,
        originalSerialKey: null, // 추가된 상태
        menuVisible: false, // 메뉴 상태 추가
    },
    getters: {
        isAuthenticated: state => !!state.user,
        isMenuVisible: state => state.menuVisible, // 메뉴 상태 반환
    },
    mutations: {
        // 변이(mutations) 정의
        setUser(state, user) {
            // 사용자 정보를 상태에 저장하는 뮤테이션
            state.user = user;
        },
        setOriginalSerialKey(state, key) {
            state.originalSerialKey = key; // 변이 추가
        },
        toggleMenuVisible(state) {
            state.menuVisible = !state.menuVisible; // 메뉴 상태 토글
        },
    },
    actions: {
        loginSuccess({ commit }, responseData) {
            const user = responseData ? JSON.parse(responseData) : null;
            commit('setUser', user);

            /*console.log("#####" + JSON.stringify({ user }));*/

            // 모듈 내에서 로컬 스토리지 업데이트
            localStorage.setItem('vuex_state', JSON.stringify({ user }));
        },
        logout({ commit }) {
            // 로그아웃 시 로컬 스토리지에서도 사용자 정보 제거
            localStorage.removeItem('vuex_state');
            commit('setUser', null);
        },
        saveOriginalSerialKey({ commit }, key) {
            commit('setOriginalSerialKey', key); // 변이 커밋
        },
        toggleMenu({ commit }) {
            commit('toggleMenuVisible'); // 메뉴 토글 액션
        },
    },
    modules: {
        alert,
        // 필요한 경우 모듈(modules) 추가
    },
    plugins: [createPersistedState()], // vuex-persistedstate 플러그인 추가
});