import Stomp from "stompjs";
import axiosInstance from "@/service/axiosInstance";
import {useStore} from "vuex";

let stompClient = null;
let subscription = null;

import store from "@/store/vuex";

const subscribeToAlertTopic = async (proxyInstance, topic) => {
    if (stompClient) {
        console.warn("STOMP 클라이언트가 이미 초기화되었습니다.");
        return;
    }

    try {
        // 서버에 구독 요청
        console.log("MQTT 구독을 위한 서버 요청 시작:", topic);
        const response = await axiosInstance.post("/api/mqtt/subscribe", { topic });
        console.log("서버 구독 요청 응답:", response.data);

        // STOMP 클라이언트 초기화
        const socket = new proxyInstance.$sockjs("/ws");
        stompClient = Stomp.over(socket);

        stompClient.connect({}, () => {
            console.log("STOMP 연결 성공");
            stompClient.subscribe(`/topic${topic}`, (message) => {
                if (message.body) {
                    console.log("MQTT 메시지 수신:", message.body);
                    const parsedMessage = JSON.parse(message.body);

                    // Vuex에 메시지 추가
                    store.dispatch("alert/addAlert", {
                        type: "alert",
                        message: parsedMessage.message || "알림 메시지가 도착했습니다.",
                        timestamp: new Date().toISOString(),
                    });

                    // Vuex 상태 확인
                    console.log("현재 Vuex 상태:", store.state.alert.alerts);
                } else {
                    console.warn("MQTT 메시지 수신: 빈 메시지");
                }
            });
        });
    } catch (error) {
        console.error("MQTT 구독 중 오류 발생:", error);
    }
};

const unsubscribeFromAlertTopic = () => {
    if (subscription) subscription.unsubscribe();
    if (stompClient) stompClient.disconnect();
    stompClient = null;
};

export { subscribeToAlertTopic, unsubscribeFromAlertTopic };
