// src/store/modules/alert.js
export default {
    namespaced: true,
    state: () => ({
        alerts: [], // 경고 메시지 목록
        alertCount: 0, // 경고 메시지 카운트
    }),
    mutations: {
        ADD_ALERT(state, alert) {
            state.alerts.push(alert);
            state.alertCount++;
        },
        CLEAR_ALERTS(state) {
            state.alerts = [];
            state.alertCount = 0;
        },
        REMOVE_ALERT(state, index) {
            state.alerts.splice(index, 1);
            state.alertCount--;
        },
    },
    actions: {
        addAlert({ commit }, alert) {
            commit("ADD_ALERT", alert);
        },
        clearAlerts({ commit }) {
            commit("CLEAR_ALERTS");
        },
        removeAlert({ commit }, index) {
            commit("REMOVE_ALERT", index);
        },
    },
};
